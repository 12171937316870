import React from "react";
import PropTypes from 'prop-types';
import styles from './CTA.module.scss';
import Image from 'components/Global/Image';
import { Link } from "gatsby"

const CTARoute = ({ title, subtitle, text, button_text, button_url, image }) => {
  return(
    <div className={styles.cta}>
      <div className="container position-relative">

        <div className={styles.textcontainer}>
          <div className="row z-index-1 w-100">
            <div className="col-lg-4">
              <h3 className="text-white h2">
                <span className="text-white h4 d-block">{subtitle}</span>
                {title}
              </h3>
              <p className="text-white mb-4 font-bold">{text}</p>
              {button_url && button_text ? (
                <p className="mb-4">
                <Link to={button_url} className="btn btn-outline-light">{button_text}</Link>
              </p>
              ) : (<br />)}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-8 offset-lg-4 text-right">
            <div>
              <Image 
                src={image}
                alt=""
                className="mb-2"
              />
            </div>
          </div>
        </div>

      </div>
    </div>
    
  );
}

export default CTARoute;

CTARoute.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  button_text: PropTypes.string,
  button_url: PropTypes.string
}