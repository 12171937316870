import React from "react";
import PropTypes from 'prop-types';
import styles from './CTAOefeningen.module.scss';
import Image from 'components/Global/Image';

const CTAOefeningen = ({ title, subtitle, text, button_text, button_url, image }) => {
  
  return(
    <div className={`${styles.cta} py-6`}>
      
      <div className={styles.bergen}></div>
      
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <Image 
              src={image} 
              alt="" 
              className="img-fluid"
            />
          </div>
          <div className="col-md-6 d-flex align-items-center">
            <div className="p-1 p-md-0">
              <h2 className="text-primary"><span className="h4">{subtitle}</span><br />{title}</h2>
              <p className="mb-4">{text}</p>
              <p className="mb-4">
                <a href={button_url} className="btn btn-outline-secondary">{button_text}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CTAOefeningen;

CTAOefeningen.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  button_text: PropTypes.string,
  button_url: PropTypes.string
}