import React from "react";
import PropTypes from 'prop-types';
import Image from 'components/Global/Image';
import styles from "./CTAFoto.module.scss";

const CTAFoto = ({ title, subtitle, text, button_text, button_url, image }) => {
  return (
    <div className={styles.ctafoto}>
      
      <div className={styles.boxWrap}>
        <div className={styles.backgroundOrange}>
          <div className={styles.inner}>
            <h3 className="text-white h2">
              <span className="text-white h4 d-block">{subtitle}</span>
              {title}
            </h3>
            <p className="text-white mb-4 font-bold">{text}</p>
            <p className="mb-4">
              <a href={button_url} className="btn btn-outline-light">{button_text}</a>
            </p>
          </div>
        </div>
      </div>

      <div>
        <Image
          src={image} 
          className={styles.image}
        />
      </div>

    </div>
  );
}

export default CTAFoto;

CTAFoto.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  button_text: PropTypes.string,
  button_url: PropTypes.string
}