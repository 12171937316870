export const linkResolver = ({ node, key, value }) => (doc) => {
  if (doc.type === 'route') return "/" + doc.uid;
  return `/${doc.id}`
}

export const getURl = (link) => {

  if(link.link_type === "Document") {
    return `/${link.uid}`;
  } else {
    return link.url;
  }
  
}