import React from "react"
import styles from "./Splash.module.scss";
import Bergen from "images/graphics/bergen.svg";
import Hardlopen from 'images/graphics/vrouw-hardlopen.svg';
import Snelwandelen from 'images/graphics/snelwandelen.svg';
import { Link } from "gatsby"

const Splash = ({ title, text, button_text, button_url }) => {
  return (
    <div className={styles.outer}>

      <div className={styles.bergen}>
        <img 
          src={Bergen}
          alt=""
        />
      </div>
      <div className={styles.hardlopen}>
        <img 
          src={Hardlopen}
          alt=""
        />
      </div>
      <div className={styles.snelwandelen}>
        <img 
          src={Snelwandelen}
          alt=""
        />
      </div>

      <div className={styles.splash}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1 className="text-white">{title}<span className="text-secondary">.</span></h1>
              <p className="text-white mb-4">{text}</p>
              {button_url && button_text &&
                <p>
                  <Link to={button_url} className={`btn btn-outline-light ${styles.buttonFocus}`}>{button_text}</Link>
                </p>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Splash;