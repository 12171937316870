import React from "react";
import styles from './RandomRoute.module.scss';
import { Link } from "gatsby"
import Prismic from 'prismic-javascript'
import { RichText } from 'prismic-reactjs'
import shuffle from 'lodash/shuffle';

const RandomRoute = () => {
  const apiEndpoint = 'https://hoornbeweegt.cdn.prismic.io/api/v2';
  const accessToken = '' // This is where you would add your access token for a Private repository

  const Client = Prismic.client(apiEndpoint, { accessToken })

  const [doc, setDocData] = React.useState(null)
  let page;

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await Client.query(
        Prismic.Predicates.at('document.type', 'route')
      )
      if (response) {
        
        setDocData( shuffle(response.results) )
      }
    }
    fetchData()

    // eslint-disable-next-line
  }, [])

  if(doc) {
    page = doc[0];
  }
  
  //page = "";

  return(
    <div className={`${styles.cta} ${!page ? 'loading': 'loaded'}`} title={!page ? 'Laden...': ''}>
      <div className="container position-relative">
        {!page && (
          <div className="sr-only">Laden...</div>
        )}
        <div className={styles.textcontainer}>
          <div className="row z-index-1 w-100">
            <div className="col-md-4">
              
              <h3 className="text-white h2">
                <span className="text-white h4 d-block">{page && RichText.asText(page.data.subtitel)  }</span>  
                {page && RichText.asText(page.data.titel)  }
              </h3>
              <p className="text-white mb-4 font-bold">{page && RichText.asText(page.data.tekst)  }</p>
              <p className="mb-4">
                {page && (
                  <Link to={`route/${page.uid}`} className="btn btn-outline-light">Ga naar route</Link>
                )}
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-8 offset-md-4 text-right">
            <div>
              {page && (
                <img 
                  src={page && page.data.afbeelding.url} 
                  className={`img-fluid ${styles.image}`}
                  alt={page && `${RichText.asText(page.data.subtitel)} ${RichText.asText(page.data.titel)}`}
                />
              )}
            </div>
          </div>
        </div>

      </div>
    </div>
    
  );
  
  
}

export default RandomRoute;