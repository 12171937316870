import React from "react";
import { graphql } from "gatsby"
import SEO from "components/Global/SEO/";
import Splash from "components/Home/Splash";
import CTARoute from "components/Home/CTARoute";
import CTAOefeningen from "components/Home/CTAOefeningen";
import CTAFoto from 'components/Home/CTAFoto';
import RandomRoute from 'components/Home/RandomRoute';
import { getURl } from './../helpers/linkResolver';

const Home = ({data}) => {

  return (
    <>
      <SEO title="Home" description="Home" />
      <Splash 
        title={data.homepage.data.titel.text}
        text={data.homepage.data.intro_tekst.text}
        button_text={data.homepage.data.button_tekst.text}
        button_url={ getURl(data.homepage.data.button_link) }
      />
      <RandomRoute />
       {data.homepage.data.body.map((item, key) => 
        {
          switch(item.slice_type) {
            case "cta_route":   
              return <CTARoute
                key={key} 
                title={item.primary.titel1.text}
                subtitle={item.primary.subtitel.text}
                text={item.primary.tekst.text}
                button_text={item.primary.button_tekst1.text}
                button_url={ getURl(item.primary.button_link) }
                image={item.primary.afbeelding}
              />;
            case "cta_oefeningen":
              return <CTAOefeningen 
                key={key} 
                title={item.primary.titel1.text}
                subtitle={item.primary.subtitel.text}
                text={item.primary.tekst.text}
                button_text={item.primary.button_tekst1.text}
                button_url={ getURl(item.primary.button_link1) }
                image={item.primary.afbeelding}
              />
            case "cta_foto":
              return <CTAFoto 
                key={key} 
                title={item.primary.titel1.text}
                subtitle={item.primary.subtitel.text}
                text={item.primary.tekst.text}
                button_text={item.primary.button_tekst1.text}
                button_url={ getURl(item.primary.button_link1) }
                image={item.primary.afbeelding}
              />
            default:
              return <h1>No project match</h1>
          }
        }
      )}
      
      
    </>
  );
}

export const query = graphql`
  query {
    route1: file(relativePath: { eq: "graphics/route1.png" }) {
      childImageSharp {
        fluid(maxWidth: 997) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    },
    foto: file(relativePath: { eq: "graphics/mockup-tegel.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1538) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    },
    homepage: prismicHomepage {
      data {
        titel {
          text
        }
        intro_tekst {
          text
        }
        button_tekst {
          text
        }
        button_link {
          url
          uid
          link_type
        }
        body {
          ... on PrismicHomepageBodyCtaRoute {
            id
            slice_type
            primary {
              afbeelding {
                localFile {
                  url
                  childImageSharp {
                    fluid(maxWidth: 997) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
              button_link {
                url
                uid
                link_type
              }
              button_tekst1 {
                text
              }
              subtitel {
                text
              }
              tekst {
                text
              }
              titel1 {
                text
              }
            }
          }
          ... on PrismicHomepageBodyCtaOefeningen {
            id
            slice_type
            primary {
              afbeelding {
                localFile {
                  url
                  childImageSharp {
                    fluid(maxWidth: 997) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
              button_link1 {
                url
                uid
                link_type
              }
              button_tekst1 {
                text
              }
              subtitel {
                text
              }
              tekst {
                text
              }
              titel1 {
                text
              }
            }
          }
          ... on PrismicHomepageBodyCtaFoto {
            id
            slice_type
            primary {
              afbeelding {
                localFile {
                  url
                  childImageSharp {
                    fluid(maxWidth: 997) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
              button_link1 {
                url
                uid
                link_type
              }
              button_tekst1 {
                text
              }
              subtitel {
                text
              }
              tekst {
                text
              }
              titel1 {
                text
              }
            }
          }
        }
      }
    }
  }
`

export default Home;